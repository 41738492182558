import React from 'react'
import { Button, Col, Form, Input, Layout, notification, Row, Space, Typography } from 'antd'
import { Content, Header } from 'antd/lib/layout/layout'
import RecrdsSubNavBar from '../Shared/RecrdsSubNavBar';
const { Text, Title } = Typography;
import { useUserStore } from '../../context/UserStore';


export default function ReindexRecords() {

    const user = useUserStore((state) => state.user);

    const submitBirthRecord = async (birthrecords) => {
        return fetch(`${process.env.REACT_APP_API_URL}birth_records/reprocess_records`, {
            method: 'post',
            headers: {
                "Authorization": `Bearer ${user.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(birthrecords),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(res);
                }
            })
            .then((json) => {
                notification.success({
                    message: 'Records reprocessed',
                    description: 'The records have been reprocessed',
                })
            })
            .catch((error) => {
                notification.error({
                    message: 'Failed to Process Birth Records',
                    description: 'Something went wrong with the reindexing.',
                    onClick: () => {
                        console.log('Notification clicked!');
                    }
                })
                console.log(error);
            })
    }

    const onFinish = (values) => {
        submitBirthRecord({
            birthrecords: {
                start_birth_record_id: values.start_birth_record_id,
                end_birth_record_id: values.end_birth_record_id
            }
        })
    }
    const onFinishFailed = (errorInfo) => {
        notification.error({
            message: 'Failed to Process Birth Records',
            description: 'Something went wrong with the reindexing.',
            onClick: () => {
                console.log('Notification clicked!');
            }
        })
    }
    return (
        <Layout>
            <RecrdsSubNavBar />
            <Space>
                <Header style={{ backgroundColor: '#ffffff' }}>
                    <Row>
                        <Col xs={24}>
                            <Title>Reindex Records</Title>
                        </Col>
                    </Row>
                </Header>
            </Space>
            <Content>
                <Row>
                    <Col xs={12} offset={1}>
                        <Text>
                            <p>Enter a Birth Record ID into the field. If the record exists in the database, it will be purged from the system.</p>
                            <p>If you would like to reprocess a <strong>RANGE</strong> of birth records, enter a birth record id in the second field.</p>
                            <p><strong>Note: This will process a consecutive range of birth records. Think: 0000001...0000010</strong></p>
                            <p>When you get a success response, please put the folder containing the record into the rapid-upload folder.</p>
                            <p>If you submitted a range of birth records, then you <strong>must</strong> place the entire range of records in the rapid upload folder.</p>
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} offset={1}>
                        <Form
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="Birth Record ID"
                                name="start_birth_record_id"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please entering a birth record id.'
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Ending Birth Record ID (not required)"
                                name="end_birth_record_id"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please entering a birth record id.'
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Button

                                    htmlType='submit'
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
